import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignIn, apiSignOut, apiSignUp } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { setLang } from 'store/locale/localeSlice'
import { setCurrentEstablishment, setListEstablishments } from 'store/base/adminSlice'
import { includes } from 'lodash'
import { setLayout } from 'store/theme/themeSlice'
import { THEME_ENUM } from 'constants/theme.constant'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state?.auth?.session)

    const signIn = async (values) => {
        try {
            const resp = await apiSignIn(values)
            if (resp.data) {
                const { success, signIn, user, message } = resp.data

                if(success && signIn) {
                    let authenticatedEntryPath = appConfig.authenticatedEntryPath
                    dispatch(setLang(user?.country?.lang))
                    dispatch(onSignInSuccess(user?.token))
                    if (user) {
                        const userAuthority = [
                            'User',
                            ...user?.roles,
                            ...user?.permissions,
                        ]

                        if(includes(userAuthority, 'User Admin') 
                            || includes(userAuthority, 'Administrator') 
                            || includes(userAuthority, 'Affiliater')) {
                            authenticatedEntryPath = '/dashboard'
                            dispatch(setLayout(THEME_ENUM.LAYOUT_TYPE_CLASSIC))
                        } else {
                            if(includes(userAuthority, 'Waitress')) {
                                authenticatedEntryPath = '/tables'
                            }
                            dispatch(setLayout(THEME_ENUM.LAYOUT_TYPE_MODAL))
                        }

                        dispatch(setUser({
                            ...user,
                            userName: user.username,
                            authority: userAuthority,
                        }))
                    }
                    const redirectUrl = query.get(REDIRECT_URL_KEY)
                    navigate(
                        redirectUrl ? redirectUrl : authenticatedEntryPath
                    )
                    return {
                        status: 'success',
                        message: '',
                    }
                } else  {
                    return {
                        status: 'failed',
                        message,
                    }
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['User'],
                                email: '',
                            }
                        )
                    )
                }
                const redirectUrl = query.get(REDIRECT_URL_KEY)
                navigate(
                    redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(setCurrentEstablishment(null))
        dispatch(setListEstablishments([]))
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
