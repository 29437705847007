import { gql } from "@apollo/client";

export const MasterListGenericTable = gql`query($table: String!, $group: String, $active: Boolean, 
$visible: Boolean, $paginate: PaginateInput!) {
    data: MasterListTable(table: $table, group: $group, active: $active, visible: $visible, paginate: $paginate) {
        data {
            id
            table
            code
            short_name
            name
            description
            group
            active
            visible
        }
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
}`

export const MasterListCountries = gql`query($visible: Boolean) {
    data: MasterListCountries(visible: $visible) {
        id
        zip_code
        name
        timezone
        currency_code
        lang
        flag
    }
}`

export const MasterListCities = gql`query($countryId: String, $paginate: PaginateInput) {
    data: MasterListCities(country_id: $countryId, paginate: $paginate) {
        data {
            id
            name
            ubigeo
            country_id
            departament
            province
            district
        }
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
}`

export const MasterListEstablishmentZones = gql`query($eid: String!, $paginate: PaginateInput!, $active: Boolean){
    data: MasterListEstablishmentZones(establishment_id: $eid, paginate: $paginate, active: $active) {
        data {
            id
            establishment_id
            description
            level
            active
        }
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
}`

export const MastersListEstablishmentTable = gql`query($eid: String!, $zid: Int, $paginate: PaginateInput!, $active: Boolean) {
    data: MasterListEstablishmentTables(establishment_id: $eid, zone_id: $zid, paginate: $paginate, active: $active) {
        data {
            id
            number
            description
            chairs
            active
        }
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
}`

export const MasterGetEstablishmentTable = gql`query($id: Int!) {
    data: MasterGetEstablishmentTable(id: $id) {
        id
        description
        number
        active
        chairs
    }
}`

export const MasterGetEstablishmentById = gql`query($id: String!) {
    data: MasterGetEstablishmentById(id: $id) {
        id
        external_id
        country_id
        city_id
        company_id
        document_type_id
        identifier_number
        legal_name
        trade_name
        slug
        description
        status
        status_description
        visible
        active
        attention_state
        logo_url
        logo_thumbnail_url
        banner_url
        banner_thumbnail_url
        mapeable_old {
            modelable_type
            modelable_id
            oldable_type
            oldable_id
        }
        document_type_option: document_type {
            value: id
            label: name
        }
        address {
            id
            country_id
            city_id
            code
            name
            description
            urbanization
            latitude
            longitude
            reference
            cellphone
            instructions
            primary
            addressable_id
            addressable_type
            country_option: country {
                value: id
                label: name
                id
                name
                flag
                timezone
                zip_code
            }
            city_option: city {
                value: id
                label: name
            }
        }
        company {
            id
            country_id
            city_id
            legal_name
            mapeable_old {
                modelable_type
                modelable_id
                oldable_type
                oldable_id
            }
        }
        categories {
            value: id
            label: name
        }
    }
}`

export const MasterStoreEstablishmentTable = gql`mutation($id: Int, $eId: String!, $number: Int!, $description: String!, $chairs: Int, $active: Boolean) {
    data: MasterStoreEstablishmentTable(id: $id, establishment_id: $eId, number: $number, description: $description, chairs: $chairs, active: $active) {
        success
        message
    }
}`


export const MasterListEstablishmentCatalogs = gql`query($eid: String!, $paginate: PaginateInput!, $active: Boolean, $visible: Boolean) {
    records: MasterListEstablishmentCatalogs(establishment_id: $eid, paginate: $paginate, active: $active, visible: $visible) {
        data {
            id
            external_id
            name
            description
            all_days
            schedules {
                label
                value
                items
                active
            }
            visible
            active
            favorite
            position
        }
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
}`

export const MasterListCatalogsWithItems = gql`query($eid: String!, $paginate: PaginateInput!, $active: Boolean, $visible: Boolean) {
    records: MasterListEstablishmentCatalogs(establishment_id: $eid, paginate: $paginate, active: $active, visible: $visible) {
        data {
            id
            external_id
            name
            description
            all_days
            schedules {
                label
                value
                items
                active
            }
            visible
            active
            favorite
            position
            items {
                id
                external_id
                name
                price
                price_minimum
                price_promo
            }
        }
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
}`

export const MasterGetCatalogIdQuery = gql`query($id: String!) {
    data: MasterGetCatalogId(id: $id) {
        id
        external_id
        establishment_id
        name
        description
        all_days
        days {
            label
            value
        }
        schedules {
            label
            value
            items
            active
        }
        establishment {
            id
            external_id
            legal_name
        }
        visible
        active
        favorite
        position
    }
}`

export const MasterStoreCatalog = gql`mutation($id: String, $eId: String!, $name: String!, $desc: String, 
$active: Boolean!, $visible: Boolean!, $schedules: [ScheduleJsonInput], $allDays: Boolean!, $favorite: Boolean, $index: Int ) {
    data: MasterStoreCatalog(external_id: $id, establishment_id: $eId, name: $name, description: $desc,
    active: $active, visible: $visible, schedules: $schedules, all_days: $allDays, favorite: $favorite, position: $index) {
        success
        message
    }
}`

export const MasterUpdateStateCatalogMutate = gql`mutation($eId: String!, $col: String!, $value: Boolean) {
    data: MasterUpdateStateCatalog(external_id: $eId, col: $col, value: $value) {
        success
        message
    }
}`

export const MasterListEstablishmentItems = gql`query($eId: String, $cId: String, $paginate: PaginateInput!, $active: Boolean, $visible: Boolean) {
    records: MasterListEstablishmentItems(establishment_id: $eId, catalog_id: $cId, paginate: $paginate, active: $active, visible: $visible) {
        data {
            id
            external_id
            establishment_id
            item_code
            item_code_gsl
            name
            description
            thumbnail
            price
            price_minimum
            price_promo
            active
            visible
            favorite
        }
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
}`

export const MasterGetItemById = gql`query($id: String!) {
    data: MasterGetItemById(id: $id) {
        id
        external_id
        catalog_id
        establishment_id
        item_code
        item_code_gsl
        barcode
        name
        description
        price
        price_with_shipping
        price_minimum
        price_promo
        active
        visible
        favorite
        sold_out
        thumbnail
        sliders {
            xs {
                url
                filename
                path
                size
            }
            sm {
                url
                filename
                path
                size
            }
            md {
                url
                filename
                path
                size
            }
            lg {
                url
                filename
                path
                size
            }
            xl {
                url
                filename
                path
                size
            }
        }
        attributes {
            label
            value
        }
        options {
            id
            establishment_id
            description
            once
            min
            max
            position
            visible
            active
            options {
                id
                description
                price
                age_majority
                active
                visible
            }
        }
    }
}`

export const MasterListEstablishmentOptions = gql`query($eId: String!, $paginate: PaginateInput!, $active: Boolean, $visible: Boolean) {
    records: MasterListEstablishmentOptions(establishment_id: $eId, paginate: $paginate, active: $active, visible: $visible) {
        data {
            id
            description
            once
            min
            max
            active
            visible
            options {
                id
                description
                price
                visible
                active
            }
        }
        total
        per_page
        current_page
        from
        to
        last_page
        has_more_pages
    }
}`

export const MasterGetOptionById = gql`query($id: Int!) {
    data: MasterGetOptionById(id: $id) {
        id
        establishment_id
        description
        once
        min
        max
        visible
        active
        options {
            id
            item_id
            description
            price
            age_majority
            visible
            active
        }
    }
}`

export const MasterStoreItem = gql`mutation($item: ItemInput, $uploads: [Upload], $remove: [ImageSizeInput]) {
    data: MasterStoreItem(item: $item, uploads: $uploads, remove_sliders: $remove) {
        success
        message
    }
}`

export const MasterStoreOption = gql`mutation($option: OptionInput!) {
    data: MasterStoreOption(option: $option){
        success
        message
    }
}`

export const MasterUpdatePosition = gql`mutation($model: String!, $id: Int!, $position: Int) {
    data: MasterUpdatePosition(model: $model, id: $id, position: $position) {
        success
        message
    }
}`

export const MasterStoreEstablishment = gql`mutation($establishment: EstablishmentInput) {
    data: MasterStoreEstablishment(establishment: $establishment) {
        success
        message
    }
}`

export const MasterStoreAddress = gql`mutation($address: AddressInput) {
    data: MasterStoreAddress(address: $address) {
        success
        message
    }
}`

export const MasterStorePromotion = gql`mutation($promotion: PromotionInput) {
    data: MasterStorePromotion(promotion: $promotion) {
        success
        message
    }
}`

export const MasterListEstablishmentPromotions = gql`query($status: String!, $establishment_id: String! $paginate: PaginateInput!, $active: Boolean, $visible: Boolean) {
    data: MasterListEstablishmentPromotions(status: $status, establishment_id: $establishment_id, paginate: $paginate, active: $active, visible: $visible) {
        data {
            id
            external_id
            establishment_id
            country_id
            city_id
            description
            start_date
            end_date
            all_day
            start_time
            end_time
            status
            status_text
            days
            active
            visible
        }
        total
        per_page
        current_page
        last_page
    }
}`

export const MasterGetPromotionById = gql`query($external_id: String!) {
    data: MasterGetPromotionById(external_id: $external_id) {
        id
        external_id
        establishment_id
        country_id
        city_id
        description
        discount_type
        discount_value
        promotion_type
        start_date
        end_date
        all_day
        start_time
        end_time
        status
        status_text
        days
        items {
            id
            promotion_id
            description
            price
            price_promo
            relationable_type
            relationable_id
        }
        active
        visible
    }
}`

export const MasterListChallengesQuery = gql`query($active: Boolean, $visible: Boolean) {
    data: MasterListChallenges(active: $active, visible: $visible) {
        id
        external_id
        title
        image
        description
        active
        visible
        to_url
        user_id
    }
}`

export const MasterListAwardQuery = gql`query($paginate: PaginateInput!, $status: String, $establishment_id: ID!, $active: Boolean, $visible: Boolean) {
    data: MasterListAwards(paginate: $paginate, status: $status, establishment_id: $establishment_id, active: $active, visible: $visible) {
        data {
            id
            internal_code
            description
            points
            stock
            thumbnail
            relationable_id
            relationable_type
            status
            status_text
            active
            visible
        }
        total
        per_page
        current_page
        last_page
        has_more_pages
    }
}`

export const MasterListCompaniesQuery = gql`query($states: [String], $paginate: PaginateInput!, $cityId: Int!, $active: Boolean, $trashed: Boolean) {
    data: MasterListCompanies(states: $states, paginate: $paginate, city_id: $cityId, active: $active, trashed: $trashed)
    {
        data {
            id
            country_id
            city_id
            ubigeo
            legal_name
            trade_name
            identifier_number
            address
            state
            mapeable_old {
                modelable_type
                modelable_id
                oldable_type
                oldable_id
            }
            country {
                id
                name
                flag
            }
            city {
                id
                name
            }
        }
        total
        per_page
        current_page
        last_page
        has_more_pages
    }
}`

export const MasterGetCompanyByIdQuery = gql`query($id: Int!) {
    data: MasterGetCompanyById(id: $id) {
        id
        identifier_number
        legal_name
        trade_name
        address
        country_id
        city_id
        state
        active
        country {
            id
            name
            flag
        }
        city {
            id
            name
        }
    }
}`


export const MasterListEstablishmentsQuery = gql`query($company_id: Int, $city_id: Int!, $paginate: PaginateInput!) {
    data: MasterListEstablishments(company_id: $company_id, city_id: $city_id, paginate: $paginate) {
        data {
            id
            external_id
            country_id
            city_id
            company_id
            document_type_id
            identifier_number
            legal_name
            trade_name
            slug
            description
            status
            rating
            favorite
            saturated
            attention_state
            position
            logo_thumbnail_url
            banner_thumbnail_url
            visible
            active
            mapeable_old {
                modelable_type
                modelable_id
                oldable_type
                oldable_id
            }
        }
        total
        per_page
        current_page
        last_page
    }
}`

export const MasterListUsersQuery = gql`query($col: String!, $admin: Boolean, $states: [String], $paginate: PaginateInput!) {
    data: MasterListUsers(column: $col, admin: $admin, states: $states, paginate: $paginate) {
        data {
            id
            external_id
            name
            username
            email
            country_id
            city_id
            person_id
            status
            created_at
            is_admin
            country {
                id
                name
                flag
            }
            city {
                name
                id
            }
        }
        total
        per_page
        current_page
        last_page
    }
}`

export const MasterGetUserByIdQuery = gql`query($userId: String!) {
    data: MasterGetUserById(user_id: $userId) {
        id
        external_id: uuid
        name
        username
        email
        country_id
        city_id
        status
        is_admin
        country_option: country {
            value: id
            label: name
            flag
        }
        city_option: city {
            label: name
            value: id
        }
    }
}`

export const MasterListEstablishmentApplicationsQuery = gql`query($country_id: String, $city_id: Int, $states: [String], $paginate: PaginateInput!) {
    data: MasterListEstablishmentApplication(country_id: $country_id, city_id: $city_id, states: $states, paginate: $paginate) {
        data {
            id
            establishment_identifier
            establishment_name
            establishment_address
            email
            cellphone
            given_name
            sur_name
            detail
            status
            ip
            country {
                id
                name
                flag
            }
            city {
                id
                name
            }
        }
        total
        per_page
        current_page
        last_page
    }
}`

export const MasterListCategoriesQuery = gql`query($type: CoreCategoryEnum!, $paginate: PaginateInput!, $active: Boolean, $visible: Boolean) {
    data: MasterListCategories(type: $type, paginate: $paginate, active: $active, visible: $visible) {
        data {
            id
            name
            image
            icon
            description
            active
            visible
            favorite
        }
        total
        per_page
        current_page
        last_page
    }
}`

export const MastersGetAwardByIdQuery = gql`query($id: ID!) {
    data: MastersGetAwardById(id: $id) {
        id
        internal_code
        description
        stock
        thumbnail
        points
        status
        relationable_type
        relationable_id
        active
        visible
        author_id
        establishment_id
        start_date
        end_date
    }
}`

export const MasterListEstablishmentSettingsQuery = gql`query($eid: ID!, $names: [String]) {
    data: MasterListEstablishmentSettings(establishment_id: $eid, setting_names: $names) {
        id
        label
        description
        name
        value
        type
        editable
        is_global
        attributes
        data
        position
        city_id
        country_id
        adjustable_type
        adjustable_id
        active
        visible
    }
}`

export const MasterListAwardsExchangesQuery = gql`query($status: [String], $establishment_id: ID!, $user_id: ID, $active: Boolean, $visible: Boolean, $paginate: PaginateInput!) {
    data: MasterListAwardsExchanges(status: $status, establishment_id: $establishment_id, user_id: $user_id, active: $active, visible: $visible, paginate: $paginate) {
        data {
            id
            establishment_id
            user_id
            author_id
            code
            quantity
            points
            status
            exchange_date
            return_date
            active
            visible
            created_at
            updated_at
            award {
                id
                internal_code
                description
                thumbnail
                status
                points
            }
            user {
                id
                name
                avatar
            }
        }
        total
        per_page
        current_page
        last_page
    }
}`